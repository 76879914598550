import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private socialAuthService: SocialAuthService, private msalService: MsalService) { }

  authenticate(username, password) {
    if (username === "javainuse" && password === "password") {
      sessionStorage.setItem('username', username)
      return true;
    } else {
      return false;
    }
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem('username')
    return !(user === null) || (user === 'dumy')
  }

  logOut() {
    sessionStorage.removeItem('username')
    if(sessionStorage.getItem('provider')=="google") {
      this.socialAuthService.signOut(true);
    } else if(sessionStorage.getItem('provider')=="microsoft") {
      this.msalService.logout();
    }

  }
}
