import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest} from '@angular/common/http';
import {ApiConfigService} from './../api.service';
import 'rxjs/add/operator/map'
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  options: any;
  localStorgeDetails: any;
  token: any;
  routeParams: any;
  private baseUrl = 'http://localhost:8080/api/v1/employees';

  constructor(private http: HttpClient, private apiService: ApiConfigService) {
    // const headers = new Headers();
   }

//   getEmployee(id: number): Observable<any> {
//     // return this.http.get(`${this.baseUrl}/${id}`);
//     return this.http.get(this.apiService.config+id);
//   }

  checkLogin(loginDetails){
    return this.http.post(this.apiService.config+'/checkLogin', loginDetails)
  }

  saveMappingData(data): Observable<any> {
    return this.http.post(this.apiService.config + '/police/mapping', data).map(response => response);
  }

  uploadFiles(files){
    return this.http.post(this.apiService.config+'/api/upload/policy/doc',files).map(response => response);
  }

  readAllPoliceDocument(){
    return this.http.get(this.apiService.config+'/read/documents')
  }
  
  loginDrive() {
    return this.http.get(this.apiService.config + '/login/drive');
  }

  getListDrive() {
    return this.http.get(this.apiService.config + '/drive/list',
        this.options).map(response => JSON.parse(JSON.stringify(response)))
  }

  saveToken(code) {
    return this.http.post(this.apiService.config + '/save/drive/token', code).map(response => JSON.parse(JSON.stringify(response)));
  }

  saveObj(obj) {
    return this.http.post(this.apiService.config+'/save/obj/doc',obj).map(response => response);
  }

  saveObjDoc(folderId) {
    return this.http.post(this.apiService.config+'/upload/drive/folder/files',folderId).map(response => JSON.parse(JSON.stringify(response)));
  }

  getOnedriveAccessToken() {
    return this.http.get(this.apiService.config + 
      '/get/onedrive/accessToken').map(response => JSON.parse(JSON.stringify(response)))
  }
  deleteExcelFile(excelFileName){
    return this.http.get(this.apiService.config + '/delete/excelFile/'+ excelFileName,
    this.options).map(response => JSON.parse(JSON.stringify(response)))
  }
}   