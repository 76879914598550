import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import {AuthenticationGuard} from './authentication.guard'; 
import { DriveDocumentListComponent } from './upload-document/drive-document-list/drive-document-list.component'

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'document', component: DriveDocumentListComponent},
  { path: 'upload', component: UploadDocumentComponent, canActivate: [AuthenticationGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
