<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
<div class="dialog-content- wrapper" style="width:100%;height:100%">
    <div fxLayout="row" fxLayoutAlign="start center" class="header-border">
        <div style="padding-left:1%;" class="col-md-10">
            <h2 style="font-size: 22px;">Read Document File</h2>
        </div>
        
    </div>

    <div class="content-card content-border">
        <div>
          <div fxLayout="row">
            <div fxFlex.gt-md="100%" style="margin-bottom: 10px;">
                <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100">
                        <h3>Upload Documents</h3>
                        <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                                <input type="file" #fileDropRef id="fileDropRef"  multiple (change)="fileBrowseHandler($event.target.files)" accept=".doc,.docx,application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                <img src="assets/file_upload.svg" style="width: 5%;
                                margin-right: 3%;">
                                <h3>Drag and drop file here</h3>
                                <!-- <h3>or</h3>
                                <label for="fileDropRef">Browse for file</label> -->
                            </div>
                    <!-- <div class="dragfileformat">
                        <h3>Upload Documents</h3>
                        <div>
                            <div ng2FileDrop
                                [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                [uploader]="uploader"
                                class="well my-drop-zone"
                                 webkitdirectory multiple>
                                Drag Your Documents Here
                                
                            </div>
                        </div>
                    </div> -->
                </div>
                <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100">
                    <div class="dragfileformat">
                        <!-- <div style="padding:10px">
                            Single
                            <input id="filesId" type="file" id="customFile" (change)="selectFile($event)" webkitdirectory multiple >
                            <button mat-raised-button style="background-color: yellow;"
                                class="save-btn" (click)="loadGoogleDrive()"><img src="../assets/drive.png" style="height: 10%;
                                width: 10%;
                                margin: 10px;">Google Drive
                            </button>
                        </div> -->
                        <div class="row" style="padding:10px;display: -webkit-inline-box;width: 100%;" >
                            <div class="col-sm-6">
                            <span>Single</span>
                            <input id="filesId" type="file" id="customFile" (change)="selectFile($event)" webkitdirectory multiple style="margin-left: 10px;" accept=".doc,.docx,application/pdf,application/msword" />
                            </div>
                            <div class="col-sm-6">
                            <span *ngIf="provider=='google'">
                                <button *ngIf="!selectedFiles || selectedFiles.length==0" mat-raised-button style="background-color: yellow;width:60%"
                                class="save-btn" (click)="loadGoogleDrive()"><img src="assets/drive.png" style="width: 10%;
                                margin-right: 3%;"><span>Google Drive</span></button>
                            </span>
                            <!-- <span *ngIf="provider=='microsoft'">
                                <button *ngIf="!selectedFiles || selectedFiles.length==0 && provider=='microsoft'" mat-raised-button style="background-color: #E8E8E8;width:60%"
                                class="save-btn" (click)="oneDrivePicker()"><img src="assets/onedrive.svg" style="width: 10%;
                                margin-right: 3%;"><span>Microsoft Drive</span></button>
                            </span>
                            <span *ngIf="provider=='microsoft'">
                                <button *ngIf="!selectedFiles || selectedFiles.length==0 && provider=='microsoft'" mat-raised-button style="background-color: #E8E8E8;width:60%; margin-top: 3%"
                                class="save-btn" (click)="sharedWithYou()"><img src="assets/onedrive.svg" style="width: 10%; 
                                margin-right: 3%; "><span>Shared With ME</span></button>
                            </span> -->
                            <mat-form-field style="width: 90%" >
                                <input matInput  matInput
                                    [(ngModel)]="sharedUrl"
                                    placeholder="Enter Shared URL"
                                    required>
                            </mat-form-field>
                            <span>
                                <button *ngIf="!selectedFiles || selectedFiles.length==0" mat-raised-button style="background-color: #E8E8E8;width:60%; margin-top: 3%"
                                class="save-btn" (click)="fetchFilesFromURL(sharedUrl)"><img src="assets/onedrive.svg" style="width: 10%; 
                                margin-right: 3%; "><span>fetch Files</span></button>
                            </span>
                            </div>
                        </div>
                        <!-- <div style="padding:10px">
                            <button  (click)="loadGoogleDrive()">Select From Google Drive</button>
                        </div> -->
                        <div *ngIf="selectedFiles && selectedFiles.length>=1" style="padding:10px; font-weight: 500;">
                            <p>No of Documents Selected: {{ selectedFiles ? selectedFiles.length : '0'}}</p>
                        </div>

                        <div *ngIf="fileArray && fileArray.length>=1" style="padding:10px; font-weight: 500;">
                            <p>No of Documents Selected: {{ fileArray ? fileArray.length : '0'}}</p>
                        </div>

                        <div>
                            <!-- <span style="font-weight: 500; padding:10px;">Document Name:</span>
                            <span *ngFor="let item of uploader.queue" >
                                {{ item?.file?.name }}
                                <i class="material-icons" (click)="item.remove(); removeItem();" style="font-size: 15px;cursor: pointer;">cancel</i>,
                            </span> -->
                            <span style="font-weight: 500; padding:10px;">Document Name:</span>
                            <span *ngIf="selectedFiles && selectedFiles.length>=1">
                                <span *ngFor="let item of selectedFiles;let i=index" >
                                    {{ item.name }}
                                    <i class="material-icons" (click)="removeItem(i);" style="font-size: 15px;cursor: pointer;">cancel</i>,
                                </span>
                            </span>
                            <span *ngIf="fileArray && fileArray.length>=1">
                                <span *ngFor="let item of fileArray;let i=index" >
                                    {{ item.orginalName }}
                                    <i class="material-icons" (click)="removeDriveItem(i);" style="font-size: 15px;cursor: pointer;">cancel</i>,
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="loadDrivePreviewFlag">
        <div *ngIf="previewFlag && selectedFiles!=undefined && selectedFiles.length>=1 || fileArray && fileArray.length>=1">
            <div fxLayout="row">
                <div  class="content-card content-border" fxFlex.gt-md="100%" style="float:right">
                    <div style="display: grid;
                    text-align: -webkit-center;">
                            <span>Preview</span>
                            <object width="700px" height="600px" data="https://complyology.com/policytool/documents/uploaded policy file/preview.pdf"></object>
                            <button mat-raised-button 
                                class="save-btn"
                                matTooltip="Open Document"
                                aria-label="Open Document" style="margin: 10px; width: 100px;">
                                <a href= "https://complyology.com/policytool/documents/uploaded policy file/preview.pdf" target="_blank" style="color: white;">View</a>
                        </button>
                    </div>
                </div> 
            </div> 
            <div fxLayout="row">
              <div class="content-card content-border" fxFlex.gt-md="100%" style="margin-bottom: 10px;">
                <div class="p-24 m-0" style="height: 60%;">
                    <div style="position: relative;" *ngFor = "let option of optionsList; let i = index;">
                        <div fxLayout="row" style="padding: 10px">
                            <div fxFlex.gt-md="100%">
                                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                                    <mat-form-field style="width: 90%">
                                        <input matInput
                                            [(ngModel)]="optionsList[i].fieldName"
                                            placeholder="Starting text"
                                            required disabled>
                                    </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                                    <mat-form-field style="width: 90%; margin-right: -25px;" class="formformat">
                                        <mat-select 
                                            [(ngModel)]="optionsList[i].type"
                                            placeholder="select type"
                                            required>
                                            <mat-option *ngFor="let list of typeList" value={{list}}>
                                                {{list}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="optionsList[i].type==undefined || optionsList[i].type=='Text'" fxFlex.gt-md="100%">
                                    <div>
                                        <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                                            <mat-form-field style="width: 90%">
                                                <input matInput
                                                    [(ngModel)]="option.startText"
                                                    placeholder="Starting text"
                                                    required>
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                                            <mat-form-field style="width: 90%">
                                                <input matInput (keypress)="isNumberKey($event)"
                                                    [(ngModel)]="option.skipChar"
                                                    placeholder="No of chars to skip"
                                                    >
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                                            <mat-form-field style="width: 90%">
                                                <input matInput (keypress)="isNumberKey($event)"
                                                    [(ngModel)]="option.maxChar"
                                                    placeholder="Max. Chars"
                                                    required>
                                            </mat-form-field>
                                        </div>
                                        
                                        <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100" *ngIf="!freeHandFlag">
                                            <mat-form-field style="width: 90%; margin-right: -25px;" class="formformat">
                                                <mat-select 
                                                    [(ngModel)]="optionsList[i].endingIndicator"
                                                    placeholder="Ending Indicator"
                                                    required>
                                                    <mat-option *ngFor="let list of endingIndicator" value={{list}}>
                                                        {{list}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="optionsList[i].endingIndicator == 'Text Indicator'" fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                                            <mat-form-field style="width: 90%" >
                                                <input matInput
                                                    [(ngModel)]="option.textIndicator"
                                                    placeholder="Text Indicator"
                                                    required>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" style="margin-left: 10px;">
                                                <mat-icon *ngIf="i>=1" (click)="removeOption(i)" class="mat-btn mat-red mat-24">remove_circle</mat-icon>
                                        </div>
                                    </div>
                               </div>

                               <div *ngIf="optionsList[i].type=='File Name'" fxFlex.gt-md="100%">
                                <div>
                                    <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" style="margin-left: 10px;">
                                            <mat-icon *ngIf="i>=1" (click)="removeOption(i)" class="mat-btn mat-red mat-24">remove_circle</mat-icon>
                                    </div>
                                </div>
                            </div>
                               <div *ngIf="optionsList[i].type=='Folder Name'" fxFlex.gt-md="100%">
                                    <div>
                                        <!-- <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100" *ngIf="!freeHandFlag">
                                            <mat-form-field style="width: 90%; margin-right: -25px;" class="formformat">
                                                <mat-select 
                                                    [(ngModel)]="optionsList[i].folderType"
                                                    placeholder="Select Folder Type"
                                                    required>
                                                    <mat-option *ngFor="let list of folderList" value={{list}}>
                                                        {{list}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->

                                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" style="margin-left: 10px;">
                                                <mat-icon *ngIf="i>=1" (click)="removeOption(i)" class="mat-btn mat-red mat-24">remove_circle</mat-icon>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="optionsList[i].type=='Whole Document Text'" fxFlex.gt-md="100%">
                                    <div>

                                            <!-- <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                                                    <mat-form-field style="width: 90%">
                                                        <input matInput
                                                            [(ngModel)]="optionsList[i].startText"
                                                            placeholder="Starting text"
                                                            required>
                                                    </mat-form-field>
                                                </div> -->
        
                                                <!-- <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100" style="padding: 20px 0px 7px 25px;
                                                font-size: 22px;">
                                                            <mat-checkbox [(ngModel)]="optionsList[i].flag">
                                              Include starting text
                                              </mat-checkbox>
                                                </div> -->
                                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" style="margin-left: 10px;">
                                                <mat-icon *ngIf="i>=1" (click)="removeOption(i)" class="mat-btn mat-red mat-24">remove_circle</mat-icon>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="optionsList[i].type=='Formula'" fxFlex.gt-md="100%">
                                    <div>
                                        <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100" *ngIf="!freeHandFlag">
                                            <mat-form-field style="width: 90%; margin-right: -25px;" class="formformat">
                                                <mat-select 
                                                    [(ngModel)]="optionsList[i].baseValue"
                                                    placeholder="Base Value"
                                                    required>
                                                    <mat-option *ngFor="let list of baseValuesList" value={{list}}>
                                                        {{list}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100" *ngIf="!freeHandFlag">
                                            <mat-form-field style="width: 90%; margin-right: -25px;" class="formformat">
                                                <mat-select 
                                                    [(ngModel)]="optionsList[i].calType"
                                                    placeholder="calculation Type"
                                                    required>
                                                    <mat-option *ngFor="let list of calculationValuesList" value={{list}}>
                                                        {{list}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                                            <mat-form-field style="width: 90%" >
                                                <input matInput  matInput
                                                    [(ngModel)]="option.calValue"
                                                    placeholder="calculation Value"
                                                    required
                                                    (keypress) ="isNumberKey($event)">
                                            </mat-form-field>
                                        </div>

                                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" style="margin-left: 10px;">
                                                <mat-icon *ngIf="i>=1" (click)="removeOption(i)" class="mat-btn mat-red mat-24">remove_circle</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div>
                        <mat-icon matTooltip = "Add Options" style="margin-top: 2%;margin-left: 5px;cursor: pointer;" (click)="addOption(optionsList)" class="mat-green mat-24">add_box</mat-icon>  
                    </div> -->
                </div>
              </div> 
            </div>
        </div> 
        <div fxLayout="row" style="padding:1%" class="content-card content-border">
            <div class="col-md-5">
        </div>
        <div *ngIf="fileArray && fileArray.length>=1" class="col-md-2">
                <button mat-raised-button  [style.background-color]="homePage.buttonColor" style="width: 100%;" 
                class="save-btn"
                matTooltip="Upload"
                aria-label="SAVE"
                (click)="mappingData1()">
                <span style="color: white;">Upload</span>
            </button>
        </div>
        <div *ngIf="selectedFiles && selectedFiles.length>=1" class="col-md-2">
            <button mat-raised-button  [style.background-color]="homePage.buttonColor" style="width: 100%;margin-top: 8px;" 
            class="save-btn"
            matTooltip="Upload"
            aria-label="SAVE"
            (click)="mappingData()">
            <span style="color: white;">Upload</span>
        </button>
    </div>
        <div class="col-md-2" *ngIf="downloadFlag">
            <a [href]="downloadLocation" target="_blank" style="color:white"><button (click)="getFile()" type="button" class="btn btn-block btn-primary">Download</button></a>
        </div>
        <div class="col-md-5">
            <button  id="uploadFile" (click)="uploadFilesArray()" style="display:none">upload Dialog</button>
        </div> 
        </div>
    </div>    
</div> 
<button id="simpleClick" (click)="empty()" style="display: none;"></button>