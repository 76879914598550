import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Headers, Http, RequestOptions } from '@angular/http';
import {ApiConfigService} from './../api.service';
declare var $: any;
@Injectable({
providedIn: 'root'})
export class UploadFileService {
  options: any;
  constructor(private http: HttpClient, private apiService: ApiConfigService) {
    const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Host', 'login.microsoftonline.com');
        this.options = new RequestOptions({ headers: headers });
   }
//   pushFileToStorage(file: FileList): Observable<HttpEvent<{}>> {
//     const data: FormData = new FormData();
//     for(let i=0; i<file.length; i++) {  
//       data.append('file[]', file[i]);  
//   }

//   $.ajax({
//     type: 'POST',
//     url: 'http://localhost:8080/api/v1/upload',
//     contentType: false,
//     processData: false,
//     data: data,
//     success:function(response) {
//     }
// });
    // data.append('file[]', );
    // data.setLongIds('ids',ids);
    // let obj = {
    //     'data': data,
    //     'Ids': ids,
    // }
    // console.log(obj);
    
    // const newRequest = new HttpRequest('POST', 'http://localhost:8080/api/v1/upload', data, {
    // reportProgress: true,
    // responseType: 'text'
    // });
    // return this.https.request(newRequest); 
// }

driveFileUpload(fileArray){
    return this.http.post(this.apiService.config+'/upload/drive/file', fileArray)
  }

  oneDriveFileUpload(fileArray){
    return this.http.post(this.apiService.config+'/upload/one/drive/file', fileArray)
  }

  generateDriveFilePreview(file){
    return this.http.post(this.apiService.config+'/upload/drive/folder/files/preview', file)
  }

  getText() {
    return this.http.get(this.apiService.config+'/get/image/text');
  }

  getOnedriveAccessToken() {
    return this.http.get(this.apiService.config + '/get/onedrive/accessToken');
  }

  FilesFromurl(token, actualurl, isPersonalAccount) {
    return this.http.get(this.apiService.config + '/microsoft/url/shared/upload/' + token + '/' + actualurl + '/' + isPersonalAccount,
        this.options).map(response => JSON.parse(JSON.stringify(response)))
}
}