<div class="container" style="padding-left: 18%;
  padding-right: 18%;
  padding-top: 5%;">
    <form  method="post">
      <div class="container">
        <label for="uname"><b>Username</b></label>
        <input type="text" placeholder="Enter Username" name="uname" [(ngModel)]="username" required>
    
        <label for="psw"><b>Password</b></label>
        <input type="password" placeholder="Enter Password" name="psw" [(ngModel)]="password" required>

  <button type="submit" (click)="checkLogin()" style="margin-top: 7%;">Login</button>
  <!-- <div>
    <button type="button" (click)="loginWithGoogle()" class="btn btn-danger">Login with Google</button>
  </div> -->
    <div style="display:flex;margin-top:4%">
      <div class="google-btn" (click)="loginWithGoogle()" style="padding: 4px 30px 4px 2px;
      height: 50px;
      margin-right: 11px;
      width: 50%;">
          <div class="google-icon-wrapper">
            <img class="google-icon" src="assets/google.svg"/>
          </div>
          <p class="btn-text"><b>Sign in with google</b></p>
        </div>
        <div class="google-btn" (click)="login()" style="padding: 4px 15px 4px 2px;
        height: 50px;
        width: 50%;background-color:#EEEEEE !important">
            <div class="google-icon-wrapper">
              <img class="google-icon" src="assets/microsoft.svg"/>
            </div>
            <p class="btn-text" style="color:black;">Sign in with Microsoft</p>
          </div>
        
    <!-- <button type="button" (click)="loginDrive()" class="btn btn-danger">Login with Google</button> -->
    <!-- <button (click)="loginDrive()" class="loginBtn loginBtn--google" style="background-color:#dd4b39;font-size:20px"><i class="fa fa-google fa-fw">
    </i>  
    Login with Google+  
  </button> -->
  </div>
    </div>
  </form>
</div>
