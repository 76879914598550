import { Component, Inject, Injectable, OnInit, ViewEncapsulation } from '@angular/core';;
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-drive-document-list',
  templateUrl: './drive-document-list.component.html',
  styleUrls: ['./drive-document-list.component.scss']
})
export class DriveDocumentListComponent implements OnInit {
  folderList = [];
  radioSelected:any;
  radioSelectedString: any;
  radioSel: any;

  constructor(public dialogSec: MatDialogRef<DriveDocumentListComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,  private toastr: ToastrService) {
    // this.folderList = this.data.data;
    console.log(this.folderList);
    if(this.data.data.length>=1) {
      this.displayFolderList(this.data.data);
    } else {
      this.toastr.warning("No shared folders available");
    }
   }

  ngOnInit(): void {
    
  }

  displayFolderList(list) {
    list.forEach(res=> {
      let name = res.remoteItem.name;
      console.log(name.indexOf('.'));
      if(!(name.indexOf('.')>1)) {
        this.folderList.push(res)
      }
    })
  }

  getSelecteditem(){
    this.radioSel = this.folderList.find(Item => Item.remoteItem.name === this.radioSelected);
    this.radioSelectedString = JSON.stringify(this.radioSel);
    console.log(this.radioSelectedString);
    console.log(this.radioSel);

    // this.getFolderFiles(this.radioSel);
  }

  onItemChange(item){
    this.getSelecteditem();
  }

  getFolderFiles() {
    if(this.radioSel!=undefined) {
      console.log(this.radioSel.remoteItem.id);
      console.log(this.radioSel.remoteItem.parentReference.driveId);
      this.dialogSec.close(this.radioSel);
    } else {
      this.toastr.warning("No File Selected");
    }
  }
}
