import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfigService {

    config: any;
    fileServerURl: any;
    frontEndURL:any;
    clientId: any;
    clientSecretId: any;
    surveyAuthorization:any;
    constructor() {
        this.config = 'https://complyology.com/policytool/api/v1';
        this.fileServerURl = 'https://complyology.com/policytool/documents/';
        // this.frontEndURL = "http://complyology.com/policytool/";
       
        this.clientId = "960350657391-nmboml2251cput8gajoumtbnt68qhmqt.apps.googleusercontent.com";
        this.clientSecretId = "hmQm9Q0aRDGbgyqkpjElrSeW";
        this.surveyAuthorization = "bearer wvZoQR8UXo.EP3C.yOVBTUaxokVirhNWufV93y78ruY3AILwlHInQ27f6t6hB.3jAEOx-8NEbcnRvcEjpAn2BkrZ1nNXM1fapbRj70xraLT.xqxHe-FOMM0BvvjrYCXZ"; // prem
      //  this.surveyAuthorization = "bearer r4sHPl-rfFvtgusi2QVHaCPKeOHTkumDjo6XxFuYPFfCaYJpTWKVYY0COaygD09KQWsbORWZZFR-DspmEQPK84MWmVbPPdbavWW.n2WugpxFqPnHnpsroSzAcuD-VW7j"; //prayag
      
      // this.config = 'http://localhost:8085/api/v1';
      // this.fileServerURl = 'http://localhost:8091/';
      // this.frontEndURL = "http://172.31.19.120/";
    }
}