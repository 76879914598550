<mat-toolbar style="background:black;padding:1%" class="navbar">
  <div style="font-size: 25px;">
    <div>
        <img src="assets/complyology.png" style="width:20%">
      </div>
    
  </div>
  <div>
    <span *ngIf="loginService.isUserLoggedIn()" routerLink="/logout" class="nav-link" style="color:white;font-weight:600;font-size:20px">Logout</span>
  </div>
  </mat-toolbar>
  <router-outlet></router-outlet>