<div id="add-reasons" class="dialog-content- wrapper dailog-backgroung-color">
    <mat-toolbar matDialogTitle class="mat-accent m-0 tool-bar-height" style="background: #6f9bd0;
    color: #fff;">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title"> Shared Folders List </span>
            <button mat-button class="mat-icon-button" (click)="dialogSec.close()" aria-label="Close dialog">
                <mat-icon style="color: white;">close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="p-24 add-dailog-scroll">
        <div>
            <div *ngIf="folderList.length > 0">
                <div fxLayout="row">
                    <div fxFlex.gt-md="100%" style="font-weight: 500;">
                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" class="file-list-header-format">
                            Select
                        </div>
                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" class="file-list-header-format">
                            Icon
                        </div>
                        <div fxFlex.gt-sm="90" fxFlex.gt-xs="90" fxFlex="100" class="file-list-header-format">
                            File Name
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let level of folderList; let i = index;">
                <div fxLayout="row" style="height: 100%;">
                    <div fxFlex.gt-md="100%">
                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" class="file-list-format first-column"
                            style="text-transform:capitalize;" style="height: 100%;">
                            <input type="radio" [(ngModel)]="radioSelected" name="list_name" value="{{level.remoteItem.name}}" style="height: 100%; width: 60%; margin-left: 13%;" (change)="onItemChange(i)"/> 
                        </div>
                        <div fxFlex.gt-sm="5" fxFlex.gt-xs="5" fxFlex="100" class="file-list-format first-column"
                            style="text-transform:capitalize;" style="height: 100%;">
                            <mat-icon class="type-icon" style="color:#FFB300">folder</mat-icon>
                        </div>
                        
                        <div fxFlex.gt-sm="90" fxFlex.gt-xs="90" fxFlex="100" class="file-list-format" style=" text-transform:capitalize;" style="height: 100%;">
                            <div>
                                {{level.remoteItem.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button mat-raised-button 
      style="margin: 15px; background: #6f9bd0"
      class="save-btn"
      matTooltip="Upload"
      aria-label="Upload"
      (click)="getFolderFiles()">
      <mat-icon >upload</mat-icon>
      <span >Upload</span>
  </button>
</div>