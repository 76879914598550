import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { LoginService } from './../login/login.service';
// import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiConfigService } from '../api.service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
declare var window: any;
declare var $: any;
declare const gapi: any;
declare const google: any;
declare const filesArray :any;
declare const OneDrive: any;
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { UploadFileService } from './upload-file.service'
import { ThrowStmt } from '@angular/compiler';
import { DriveDocumentListComponent } from './drive-document-list/drive-document-list.component';
import { encode } from 'querystring';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadDocumentComponent implements OnInit {

  files: any[] = [];
    success: string[] = [];
    failed: string[] = [];
   
    isFile=true;
    shodata=false;


  customerId: any;
  authId: any;
  role: any;
  data: any;
  headerStyle: any;
  uploader: FileUploader;
  uo: FileUploaderOptions = {};
  token: any;
  fileList= [];
  oneDriveFolderFiles = [];
  fileArray = [];
  index = 0;
  mappingFlag: boolean = false;
  downloadFlag: boolean  =false;
  uploadForm: FormGroup;
  sharedUrl: any;
  abc = "/home/shrikant/compliatric_data/export/police.xlsx";
  dataFileLocation = "";
  readFlag: boolean = false;
  canDownload: boolean = false;
  mappingList = [];
  previewFlag: boolean = false;
  provider: any;
  loadingFlag: boolean = false;
  loadDrivePreviewFlag: boolean = true;
  public hasBaseDropZoneOver: boolean = false;
  typeList = ["Text", "Folder Name", "File Name", "Formula", "Whole Document Text"];
  folderList = ["Parent Folder", "Child Folder"];
  documentTextTypeList = ["Plain Text", "Rich Text"];
  baseValuesList = ["Effective Date", "Last Review Date", "Next Review Date", "Today"];
  calculationValuesList = ["Day's", "Month's", "Year's"];
  documentFeilds = ["Policy Title", "Policy Number", "Department", "Policy Summary", "Policy Type", "Policy Sub-Type", "Effective Date", "Last Review Date", "Next Review Date", "Policy Owner", "Formal Approver", "Formal Approval Date", "Editor"];
  endingIndicator = ["Positional", "Text Indicator", "Break", "Carriage return", "Space"];
  homePage = {
    headerTextColor: "#fff",
     headerBackgroundColor: '#e2dfdf',
    buttonColor: '#f5564a',
    buttonTextColor: '#fff',
    headerFont: 20,
    headerBold: false,
    headerItalic: false,  
}
  optionsList = [];
  title = 'File-Upload-Save';
  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  selectedFile = null;
  changeImage = false;
  downloadLocation: any;
  docList: any = [];
  downloadLocation1 = this.apiService.fileServerURl + "uploaded policy file/demo.pdf";
  downloadURL : any;
  constructor(private spinner: NgxSpinnerService, public dialog: MatDialog, private toastr: ToastrService, private router: Router,
    public apiService: ApiConfigService, private loginService: LoginService, private fb: FormBuilder, private uploadService: UploadFileService) {
      this.provider = sessionStorage.getItem('provider');
      this.uo.headers = [{ name: 'X-AUTH-TOKEN', value: this.token }, { name: 'Accept', value: 'application/json' }]
      this.makeOptionList();
      this.saveToken();
      if(this.provider=="google") {
        this.getListDrive();
      }
    }

  ngOnInit(): void {
    var hash = window.location.hash.substring(1);
      if(hash){
      console.log("hash Token: " + hash);
      var accessString = hash.indexOf("&");
      var access_token = null;
      access_token = hash.substring(13, accessString);
      console.log("Access Token: " + access_token);
      sessionStorage.setItem('username', access_token);
      }

    this.uploader = new FileUploader({ url: this.apiService.config + '/upload' });
    this.uploader.setOptions(this.uo);
    this.uploader.onAfterAddingFile = f => {
        this.toastr.info("File Added Successfully");
        this.readFlag = true;
        this.saveToken();
        if(this.provider=="google") {
          this.getListDrive();
        }
    };

    
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        if (status == 200) {
            this.toastr.success('Document Uploaded Successfully.', 'Done');
            let self = this;
            this.spinner.hide();
        } else {
            this.spinner.hide();
            this.toastr.error('Document not uploaded', 'Error');
        }
    };
  }

  getFile() {
    this.downloadLocation = this.apiService.fileServerURl + "policy data/"+this.downloadURL+".xlsx";
    setTimeout(() => {
      this.downloadFlag = false;
      this.deleteExcelFile(this.downloadURL);
    }, 3000);
  }

  deleteExcelFile(excelFileName) {
    this.loginService.deleteExcelFile(excelFileName).subscribe(res => {
      console.log(res);
     }, err => {
     console.log(err);
    });
  } 

  makeOptionList() {
    this.optionsList = [];
    var i = 0;
    this.documentFeilds.forEach(element => {
      var obj = {
        fieldName: element,
        startText: '',
        skipChar: '0',
        maxChar: '',
        endingIndicator: '',
        textIndicator: '',
        type: 'Text',
        folderType: '',
        documentTextType: '',
        baseValue: '',
        calType: '',
        calValue: '',
        calculationValue: '',
        flag:false
      };
      this.optionsList.push(obj);
      i++;
    });
    console.log(this.optionsList);
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  saveToken() {
    var code = this.getParameterByName('code',window.location.href);
console.log(code);
// var code = urlParams;
    const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get('code');
    if (code != null && code != undefined) {
      this.toastr.success("Login Successfull");
        this.spinner.show();
        var url = code.replace('/', '%2F');
        this.loginService.saveToken(url).subscribe(res => {
            if (res == 'success') {
                this.getListDrive();
            }
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
        });
    }
}

  getListDrive() {
    this.docList = [];
    this.loginService.getListDrive().subscribe(res => {
        console.log(res);
        this.docList = res;
    }, err => {
       console.log(err);
    });
}

  mapping() {
    console.log("Yes");
    this.mappingFlag = true;
  }

  goBack() {
    console.log("Yes");
    this.mappingFlag = false;
  }

  mappingData() {
    console.log(this.optionsList);
    for(let i=0;i<this.optionsList.length;i++){
      if(this.optionsList[i].type=='Text') {
        if(this.optionsList[i].endingIndicator=="" || this.optionsList[i].maxChar==""  || this.optionsList[i].startText==""){
          if(this.optionsList[i].endingIndicator=="Text Indicator") {
            if(this.optionsList[i].textIndicator=="") {
              return;
            }
          }
          this.toastr.error("please fill all madtory feild");
          return
        }
      } else if(this.optionsList[i].type=="Formula") {
        if(this.optionsList[i].baseValue=="" || this.optionsList[i].calValue=="" || this.optionsList[i].calValue=="") {
          return
        }
      } else if(this.optionsList[i].type=="Folder Name") {
        // if(this.optionsList[i].folderType=="") {
        //   return;
        // }
      } 
    }
    this.upload1(); 
  }

  mappingData1() {
    console.log(this.optionsList);
    for(let i=0;i<this.optionsList.length;i++){
      if(this.optionsList[i].type=='Text') {
        if(this.optionsList[i].endingIndicator=="" || this.optionsList[i].maxChar==""  || this.optionsList[i].startText==""){
          if(this.optionsList[i].endingIndicator=="Text Indicator") {
            if(this.optionsList[i].textIndicator=="") {
              return;
            }
          }
          this.toastr.error("please fill all madtory feild");
          return
        }
      } else if(this.optionsList[i].type=="Formula") {
        if(this.optionsList[i].baseValue=="" || this.optionsList[i].calValue=="" || this.optionsList[i].calValue=="") {
          return
        }
      } else if(this.optionsList[i].type=="Folder Name") {
        // if(this.optionsList[i].folderType=="") {
        //   return;
        // }
      } 
    }
    if(sessionStorage.getItem("provider")==="google") {
      this.driveFileUpload(); 
    } else if(sessionStorage.getItem("provider")==="microsoft" || sessionStorage.getItem("provider")==="normal") {
      this.oneDriveFileUpload();
    }
  }

  oneDriveFileUpload() {
    this.spinner.show();
    console.log(this.fileArray);
    var arr=  this.fileArray;
    this.fileArray = [];
    arr.forEach(element => {
      var obj = {
        id: element.id,
        orginalName: element.orginalName,
        mappingData: this.optionsList,
        url:element.url,
        folderName: element.folderName,

      }
      this.fileArray.push(obj);
    });
    console.log(this.fileArray);
    this.uploadService.oneDriveFileUpload(this.fileArray).subscribe(res=> {
      // console.log(res);
      this.spinner.hide()
      setTimeout(() => {
        this.downloadFlag = true;
      }, 1000);
      this.toastr.success("done","File parsed successfully");
    }, err=> {
      this.spinner.hide()
      setTimeout(() => {
        this.downloadFlag = true;
      }, 1000);
      // this.toastr.error("Failed to parse file");
    })
  }

  driveFileUpload() {
    this.spinner.show();
    console.log(this.fileArray);
    var arr=  this.fileArray;
    this.fileArray = [];
    arr.forEach(element => {
      var obj = {
        id: element.id,
        orginalName: element.orginalName,
        mappingData: this.optionsList,
        url:element.url,
      }
      this.fileArray.push(obj);
    });
    console.log(this.fileArray);
    this.uploadService.driveFileUpload(this.fileArray).subscribe(res=> {
      // console.log(res);
      this.spinner.hide()
      setTimeout(() => {
        this.downloadFlag = true;
      }, 1000);
    }, err=> {
      this.spinner.hide()
      setTimeout(() => {
        this.downloadFlag = true;
      }, 1000);
    })
  }

 

  upload1(){
      this.upload(this.selectedFiles[0])
  }

  upload(item) {
    let c = this;
    c.spinner.show();
    const data: FormData = new FormData();
    // for(let i=0; i<this.optionsList.length; i++) { 
      data.append('mapping',JSON.stringify(this.optionsList));
    // }
    for(let i=0; i<this.selectedFiles.length; i++) {  
      data.append('file[]', this.selectedFiles[i]);  
    }
    
    $.ajax({
      type: 'POST',
      url: 'https://complyology.com/policytool/api/v1/upload',
      contentType: false,
      processData: false,
      data: data,
      
      success:function(response) {
        c.spinner.hide();
        c.downloadURL = response;
      //  c.canDownload = true;
      setTimeout(() => {
        c.toastr.success('Read File Successfully', 'Success!!');
        c.downloadFlag = true;
      }, 2000);
       
      //  this.getFile();
      },
      error:function() {
        c.spinner.hide();
        c.toastr.error("failed to parse files");
      }
    });

    

  }

  upload2(item) {
    let c = this;
    this.previewFlag = false;
    c.spinner.show();
    const data: FormData = new FormData();
    const formData = new FormData();
    data.append('file', item);
    $.ajax({
      type: 'POST',
      url: 'https://complyology.com/policytool/api/v1/single/upload',
      contentType: false,
      processData: false,
      data: data,
      
      success:function(response) {
        this.previewUrl = response;
        console.log(response);
        
       
        setTimeout(() => {
          c.spinner.hide();
          c.previewFlag = true;
        }, 2000);
      },
      error:function(response) {
        c.spinner.hide();
        c.toastr.error("Failed to generate preview");
      }
    });

    

  }

  isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
    return false;
    return true;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    if(this.selectedFiles.length>0) {
      this.upload2(this.selectedFiles.item(0));
    }
  }

  selectFolder(event) {
    console.log(this.uploader);
    console.log(event);
  }

  addOption(optionsList) {
    var obj = {
        id: optionsList.length + 1,
        fieldName: '',
        startText: '',
        skipChar: '',
        maxChar: '',
        endingIndicator: '',
        textIndicator: '',
        flag:false
    }
    this.optionsList.push(obj);
}

  removeOption(index) {
    if (index > -1) {
        this.optionsList.splice(index, 1);
    }
  }


  onFolderSelected(event){
    console.log(this.uploader);
    this.uploader.queue.forEach(element => {
      console.log(element);
      this.fileList.push(element);
    });
  }

  onFileDropped($event) : void{
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files): void {
    this.prepareFilesList(files);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.isFile = true;
  }

  removeItem(index) {
   
    this.selectedFiles = $.grep(this.selectedFiles, function (file, idx) {
      return idx!=index;
    });
      document.getElementById('customFile')['value'] = "";
      document.getElementById('fileDropRef')['value'] = "";
      this.toastr.info("Flile Removed Successfully");
      this.upload2(this.selectedFiles[0]);
      console.log(this.selectedFiles);
    }

  removeDriveItem(index) {
    this.fileArray = $.grep(this.fileArray, function (file, idx) {
      return idx!=index;
    });
      document.getElementById('customFile')['value'] = "";
      this.toastr.info("File Removed Successfully");
      console.log(this.fileArray);
      this.previewFlag = false;
      this.generateGoogleDriveFilePreview(this.fileArray[0]);
    }

  generateGoogleDriveFilePreview(vm) {
    let c = this;
    this.loadDrivePreviewFlag = false;
    c.previewFlag = false;
    c.spinner.show();
      this.uploadService.generateDriveFilePreview(vm).subscribe(res=> {
        this.loadDrivePreviewFlag = true;
        setTimeout(() => {
          c.spinner.hide()
          c.spinner.hide();
          c.previewFlag = true;
        }, 2000);
      }, err=> {
        this.spinner.hide();
        this.previewFlag = true;
        this.loadDrivePreviewFlag = true;
      })
  }

  prepareFilesList(files): void {
    this.selectedFiles = files;
    console.log( this.selectedFiles);
    if(this.selectedFiles.length>0) {
      this.upload2(this.selectedFiles.item(0));
    }
  }

  getCustomDataValue() {
    const customSectionValues = {};

    var inputs = $('.f-stage input[type="text"]');
    $.each(inputs, function (j, input) {
        customSectionValues[input.id] = input.value;
    });

    inputs = $('.f-stage  input[type="date"]');
    $.each(inputs, function (j, input) {
        customSectionValues[input.id] = input.value;
    });

    inputs = $('.f-stage  input[type="number"]');
    $.each(inputs, function (j, input) {
        customSectionValues[input.id] = input.value;
    });

    inputs = $('.f-stage input[type="radio"]:checked');
    $.each(inputs, function (j, input) {
        customSectionValues[input.id] = $("input[name='" + input.id + "']:checked").val();
    });

    inputs = $('.f-stage input[type="checkbox"]:checked');
    $.each(inputs, function (j, input) {
        if (!customSectionValues[input.id]) {
            customSectionValues[input.id] = input.value;
        } else {
            customSectionValues[input.id] = customSectionValues[input.id] + "~" + input.value;

        }
    });

    const selectbox = $('.f-stage select');
    $.each(selectbox, function (j, input) {
        if (input.multiple) {
            const options = $('#' + input.id + ' option');
            let selectedValues = '';
            $.each(options, function (k, option) {
                if (option.selected) {
                    selectedValues += (option.value + ',');
                }
            });
            selectedValues = selectedValues.slice(0, -1);
            customSectionValues[input.id] = selectedValues;
        } else {
            customSectionValues[input.id] = input.value;
        }
    }); 

    const textarea = $('.f-stage textarea');
    $.each(textarea, function (j, ta) {
        customSectionValues[ta.id] = ta.value;
    });
    return customSectionValues;
}

openDriveList() {
  var obj = {
    id: "this is string",
    originalFileName: "This is original File Name"
  }

  this.loginService.saveObj(obj).subscribe(res => {
    if (res == 'success') {
        this.getListDrive();
    }
    this.spinner.hide();
    }, err => {
        this.spinner.hide();
    });
}

developerKey = 'AIzaSyDhNeCq5I8dGYcpPSOJe-mjeRvgvodMRbU';
clientId = "960350657391-veindsksvb8jh6g8ihr7ti937esllks5.apps.googleusercontent.com";
scope = [
  'profile',
  'email',
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/drive.metadata.readonly',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/drive.metadata',
  'https://www.googleapis.com/auth/drive.photos.readonly'

].join(' ');
pickerApiLoaded = false;
oauthToken?: any;
arrayOfgile:any;
loadGoogleDrive() {
  let abc = this;
  this.oauthToken = sessionStorage.getItem('username');
  gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
  gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });


}

onAuthApiLoad() {
  let abc = this;
  gapi.auth.authorize(
    {
      'client_id': abc.clientId,
      'scope': abc.scope,
      'immediate': false
    },
    this.handleAuthResult.bind(this));
}

onPickerApiLoad() {
  this.pickerApiLoaded = true;
}

public callUpload(){
}

handleAuthResult(authResult) {
  let abc = this;
  if (authResult && !authResult.error) {
    // if (this.oauthToken) {
      
      // let view = new google.picker.DocsView(google.picker.ViewId.DOCS).setIncludeFolders(true);
      // let viewPdf = new google.picker.DocsView(google.picker.ViewId.PDFS).setIncludeFolders(true);
      // let viewPresentation = new google.picker.DocsView(google.picker.ViewId.PRESENTATIONS).setIncludeFolders(true);
      var viewFolders = new google.picker.DocsView(google.picker.ViewId.FOLDERS);
      viewFolders.setMimeTypes('application/vnd.google-apps.folder');
      viewFolders.setIncludeFolders(true) 
      // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      viewFolders.setSelectFolderEnabled(true);
      // var docsView1 = new google.picker.DocsView()
      // .setIncludeFolders(true) 
      // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      // .setMimeTypes('application/vnd.google-apps.folder')
      // .setSelectFolderEnabled(true);
      // let docsView3 = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
      // .setParent('root')
      // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      // .setSelectFolderEnabled(true);
  
      let pickerBuilder = new google.picker.PickerBuilder();
      let picker = pickerBuilder.
        enableFeature(google.picker.Feature.NAV_HIDDEN).
        enableFeature(google.picker.Feature.MULTISELECT_ENABLED).
        setOAuthToken(authResult.access_token).
        // addView(view).
        // addView(viewPdf).
        // addView(viewPresentation).
        addView(viewFolders).
        // addView(docsView1).
        // addView(docsView3).
        addView(new google.picker.DocsView().setIncludeFolders(true).setOwnedByMe(true)).
        addView(new google.picker.DocsUploadView()).
        setCallback(function (e){
          if (e[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            let doc = e[google.picker.Response.DOCUMENTS];
            localStorage.setItem("files", JSON.stringify(doc));
            document.getElementById("uploadFile").click();
          }
        }).
        build();
        picker.setVisible(true);
    }
}


filearray= [];
uploadFilesArray(){
  console.log(JSON.parse(localStorage.getItem("files")));
  let abc = JSON.parse(localStorage.getItem("files"))

  
  console.log(typeof(abc));
  console.log(abc);
  let c = this;
  c.spinner.show();

  var obj = {
    id:abc[0].id,
    orginalName:abc[0].name,
    url: abc[0].url,
  }
  let arr = [];
  this.loginService.saveObjDoc(obj.id).subscribe(res => {
      console.log(res);
      this.fileArray = res;
      this.previewFlag = true;
      if(this.fileArray.length<=0) {
        this.toastr.error("Failed to fetch file from drive");
      }
      this.spinner.hide();
    }, err => {
        this.spinner.hide();
    });  
}
  extractText() {
    console.log("Called");
    this.uploadService.getText().subscribe(res=> {
      console.log(res);
    }, err=> {
      console.log(err);
    })
  }

  oneDrivePicker() {
    var token = "";
    var c = this;
    c.spinner.show();
    this.previewFlag = false;
    var odOptions = {
      clientId: "e990c3cd-c76f-4ebc-9a8f-e2fbdc305954",
      action: "query",
      multiSelect: true,
      viewType: "folders",
      advanced: {
        queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl",
        filter: "folder",
      },
      success: function(files) { console.log(files);
      token = files.accessToken;
      const data: FormData = new FormData();
      const formData = new FormData();
      data.append('accessToken', token);
      data.append('folderId', files.value[0].id); 
      // data.append('file', item);
      c.loadingFlag = false;
      $.ajax({
        type: 'POST',
        url: 'https://complyology.com/policytool/api/v1/microsoft/upload',
        contentType: false,
        processData: false,
        data: data,
        
        success:function(response) {

          console.log(response);
          c.fileArray = response;
          console.log(c.fileArray);
          // this.previewFlag = true;
          if(c.fileArray.length<=0) {
            c.toastr.error("Failed to fetch file from drive");
          }
          c.previewFlag = true;
           setTimeout(() => {
          c.spinner.hide();
          document.getElementById('simpleClick').click();
          c.loadingFlag = true;
        }, 200);
        },
        error:function(resp) {
          c.spinner.hide();
          if(resp.responseJSON.message!=null) {
            c.toastr.error(resp.responseJSON.message);
          } else {
            c.toastr.error("failed to parse files");
          }
        }
      });
      },
      cancel: function() { c.spinner.hide();
      c.toastr.info("No File Selected") },
      error: function(error) { c.spinner.hide();
      c.toastr.error("Failed to select file") }
    };

    OneDrive.open(odOptions);
  }

  empty() {

  }


  sharedWithYou() {
    var sharedFoldersList = [];
    let c = this;
    // var token = sessionStorage.getItem('username');
    //     console.log(this.token);
    //     $.ajax({
    //       url: 'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
    //       type: 'GET',
    //       beforeSend: function (xhr) {
    //         xhr.setRequestHeader('content-type', 'application/json');
    //         xhr.setRequestHeader('Authorization', 'Bearer '+this.token+'');
    //       },
    //       data: {},
    //       success: function (results) {
    //         console.log(results);
    //       },
    //       error: function (error) {
    //         console.log("Error in getting data: " + error);
    //       }
    //     });

    var token= sessionStorage.getItem("username");
      var odurl = "https://graph.microsoft.com/v1.0/me/drive/sharedWithMe"
      $.ajax({
      url: odurl,
      dataType: 'json',
      headers: { "Authorization": "Bearer "+token},
      accept: "application/json;odata.metadata=none",
      success: function(data) {
        console.log(data);
        console.log(data.value);
        sharedFoldersList = data.value;
        c.sharedFolderListPopUp(sharedFoldersList);
      },
      error:function(data) {
        console.log(data);
      },
      });
     
      
  }

  sharedFolderListPopUp(data) {
    let self = this;
    let dialogSec = self.dialog.open(DriveDocumentListComponent, {
        panelClass: 'custom-dialog-container',
        width: "90%",
        height: "90%",
        data: {
          "data": data,
        }
    });
    dialogSec.afterClosed().subscribe(res => {
      console.log(res.remoteItem.id);
      console.log(res.remoteItem.parentReference.driveId);
      this.getFolderFiles(res.remoteItem.parentReference.driveId, res.remoteItem.id);

    });
  }


  getFolderFiles(driveId, remoteItemId) {
    let c = this;
    c.spinner.show();
    var token= sessionStorage.getItem("username");
    //   var odurl = "https://graph.microsoft.com/v1.0/drives/"+driveId+"/items/"+remoteItemId+"/children"
    //   $.ajax({
    //   url: odurl,
    //   dataType: 'json',
    //   headers: { "Authorization": "Bearer "+token},
    //   accept: "application/json;odata.metadata=none",
    //   success: function(data) {
    //     c.listOfChildFile(data);
    //   },
    //   error:function(data) {
    //     console.log(data);
    //   },
    //   }); 
      const data: FormData = new FormData();
      const formData = new FormData();
      data.append('accessToken', token);
      data.append('driveId', driveId); 
      data.append('remoteItemId', remoteItemId); 
      // data.append('file', item);
      c.loadingFlag = false;
      $.ajax({
        type: 'POST',
        url: 'https://complyology.com/policytool/api/v1/microsoft/shared/upload',
        contentType: false,
        processData: false,
        data: data,
        
        success:function(response) {

          console.log(response);
          c.fileArray = response;
          console.log(c.fileArray);
          // this.previewFlag = true;
          if(c.fileArray.length<=0) {
            c.toastr.error("Failed to fetch file from drive");
          }
          c.previewFlag = true;
           setTimeout(() => {
          c.spinner.hide();
          document.getElementById('simpleClick').click();
          c.loadingFlag = true;
        }, 200);
        },
        error:function(resp) {
          c.spinner.hide();
          console.log(resp)
          if(resp.responseJSON.message!=null) {
            c.toastr.error(resp.responseJSON.message);
          } else {
            c.toastr.error("failed to parse files");
          }
        }
      });
  }

  listOfChildFile(data) {
    data.value.forEach(res=> {
      let name = res.remoteItem.name;
      console.log(name.indexOf('.'));
      if((name.indexOf('.')>1)) {
        this.oneDriveFolderFiles.push(res);
      }
    })
  }

  listOfChilderFile(data) {
    this.oneDriveFolderFiles = [];
    console.log(data);
    data.children.forEach(res=> {
      let name = res.name;
      console.log(name.indexOf('.'));
      if((name.indexOf('.')>1)) {
        this.oneDriveFolderFiles.push(res);
      }
    })
    console.log(this.oneDriveFolderFiles);
  }

  getFilesFromUrl(url, token) {
    let c = this;
    let val = url.search("sharepoint");
    console.log(val);
    var isPersonalAccount: any = false;
    if(val>0) {
      isPersonalAccount = true;
    } else {
      isPersonalAccount = false;
    }
    let base64Value = btoa(url);
    let encodedUrl = "u!" + base64Value.replace('/','_').replace('+','-');
    let actualurl = encodedUrl.replace(/=/g,'');
    // url = "https://graph.microsoft.com/v1.0/shares/"+actualurl+"/driveItem?$expand=children"
    // console.log(token);
    //     $.ajax({
    //       url: url,
    //       type: 'GET',
    //       beforeSend: function (xhr) {
    //         xhr.setRequestHeader('Authorization', 'Bearer '+token+'');
    //       },
    //       data: {},
    //       success: function (results) {
    //         console.log(results.children);
    //         // c.listOfChilderFile(results);
    //       },
    //       error: function (error) {
    //         console.log("Error in getting data: " + error);
    //       }
    //     });

      const data: FormData = new FormData();
      const formData = new FormData();
      data.append('accessToken', token);
      data.append('actualUrl', actualurl); 
      data.append('accountType', isPersonalAccount);
      // data.append('remoteItemId', remoteItemId); 
      // data.append('file', item);
      c.loadingFlag = false;
      c.spinner.show();
      $.ajax({
        type: 'POST',
        url: 'https://complyology.com/policytool/api/v1/microsoft/url/shared/upload',
        contentType: false,
        processData: false,
        data: data,
        
        success:function(response) {

          console.log(response);
          c.fileArray = response;
          console.log(c.fileArray);
          // this.previewFlag = true;
          if(c.fileArray.length<=0) {
            c.toastr.error("Failed to fetch file from drive");
          }
          c.previewFlag = true;
           setTimeout(() => {
          c.spinner.hide();
          document.getElementById('simpleClick').click();
          c.loadingFlag = true;
        }, 200);
        },
        error:function(resp) {
          c.spinner.hide();
          console.log(resp)
          if(resp.responseJSON.message!=null) {
            c.toastr.error(resp.responseJSON.message);
          } else {
            c.toastr.error("failed to parse files");
          } 
        }
      });
  }

  fetchFilesFromURL(url) {
    var token;
    this.spinner.show();
    this.loginService.getOnedriveAccessToken().subscribe(res=> {
      console.log(res);
      token = res.access_token;
      this.spinner.hide();
      this.getFilesFromUrl(url, token);
    }, err=> {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
    })
     
  }
 
}
