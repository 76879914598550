import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { ApiConfigService } from '../api.service';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { MsalService } from '@azure/msal-angular';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  username = ''
  password = ''
  invalidLogin = false;
  loginDetails: any;
  previewURL: any;
  socialUser: any;

  constructor(private router: Router, private loginService: LoginService, private msalService: MsalService,
    public apiService: ApiConfigService, private toastr: ToastrService,  private socialAuthService: SocialAuthService) { }

  ngOnInit() {
  }

  checkLogin() {
    var data = {
      userName: this.username,
      password: this.password
    }
    this.loginService.checkLogin(data).subscribe(res=>{
      console.log(res);
      this.loginDetails = res;
      if(this.loginDetails!=undefined && this.loginDetails!=null) {
        if(this.loginDetails.massage=="Success") {
          console.log("Yes");
          sessionStorage.setItem('provider', "normal");
          sessionStorage.setItem('username', data.userName);
          this.router.navigate(['/upload']).then(() => {
            this.toastr.success('Login Successfully', 'Success!!');
        });
        } else if(this.loginDetails.massage=="Failed") {
          this.toastr.error("Login Details Not Found");
        } else if(this.loginDetails.massage=="Invalid Password") {
          this.toastr.warning("Invalid Password");
          sessionStorage.setItem('provider', "normal");
        }
      }
    }, err=> {
      console.log(err);
    })
  }
    

  loginDrive() {
    this.loginService.loginDrive().subscribe(res =>{
      console.log(res);
      $('<a href="' + this.previewURL + '"  ></a>')[0].click();
      
    }, err=> {
      console.log(err);
      this.previewURL = err.error.text;
      sessionStorage.setItem('username', this.previewURL);
      $('<a href="' + this.previewURL + '"  ></a>')[0].click();
    });
}

  loginWithGoogle(): void {
    
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    setTimeout(() => {
      this.socialAuthService.authState.subscribe(user=> {
        console.log(user.response);
        this.socialUser = {
          access_token: user.response.access_token,
          scope: user.response.scope,
          token_type: user.response.token_type,
        }
  
        this.loginService.saveToken(this.socialUser).subscribe(res=> {
          console.log(res);
          if(res.error.text=="success") {
              sessionStorage.setItem('username', this.socialUser.access_token);
              sessionStorage.setItem('provider', "google");
                this.router.navigate(['/upload']).then(() => {
                this.toastr.success('Login Successfully', 'Success!!');
            });
          }
        }, err=> {
          if(err.error.text=="success") {
            sessionStorage.setItem('username', this.socialUser.access_token);
            sessionStorage.setItem('provider', "google");
            this.router.navigate(['/upload']).then(() => {
              this.toastr.success('Login Successfully', 'Success!!');
            });
          }
          console.log(err);
        })
      })
    }, 1000);
    
  }

  login(){
    
    // this.msalService.loginPopup().subscribe(res=> {
      // console.log(res);
      // let c = this;
      // this.msalService.instance.setActiveAccount(res.account);
      //   sessionStorage.setItem('username', res.accessToken);
      //   sessionStorage.setItem('provider', "microsoft");
        
      //   var token = res.accessToken;
      //   console.log(token);
      //   // $.ajax({
      //   //   url: 'https://graph.microsoft.com/v1.0/me/drive/sharedWithMe',
      //   //   type: 'GET',
      //   //   beforeSend: function (xhr) {
      //   //     xhr.setRequestHeader('content-type', 'application/json');
      //   //     xhr.setRequestHeader('Authorization', 'Bearer '+token+'');
      //   //   },
      //   //   data: {},
      //   //   success: function (results) {
      //   //     console.log(results);
      //   //   },
      //   //   error: function (error) {
      //   //     console.log("Error in getting data: " + error);
      //   //   }
      //   // });
      //   this.router.navigate(['/upload']).then(() => {
      //   this.toastr.success('Login Successfully', 'Success!!');
      //   })
      var abc = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=e990c3cd-c76f-4ebc-9a8f-e2fbdc305954&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default&response_type=token&redirect_uri=https://complyology.com/policytool/upload"
          $('<a href="' + abc + '" ></a>')[0].click();
          sessionStorage.setItem('username', "dumy");
          sessionStorage.setItem('provider', "microsoft");
    // })


  }
}
